import React from 'react';
import { Heading } from '../components/UI/Heading';
import { Root } from '../components/Root';
import { Text } from '../components/UI/Text';
import styled from '@emotion/styled/macro';

export default () => {
  return (
    <Root includeFooter={true}>
      <Content>
        <Heading type="h1">Privacy statement</Heading>
        <Text mode="tag">Dit onderdeel is voor het laatst aangepast op 2 september 2020.</Text>

        <Heading type="h3">Artikel 1 - Waarborgen Privacy</Heading>
        <Text mode="normal">
          Het waarborgen van de privacy van bezoekers van crossworx.be is een belangrijke taak voor ons. Daarom beschrijven we in onze privacy policy welke informatie we verzamelen en hoe we deze informatie gebruiken.
        </Text>
        <br /><br />

        <Heading type="h3">Artikel 2 - Toestemming</Heading>
        <Text mode="normal">Door de informatie en de diensten op crossworx.be te gebruiken, gaat u akkoord met onze privacy policy en de voorwaarden die wij hierin hebben opgenomen.</Text>
        <br /><br />

        <Heading type="h3">Artikel 3 - Vragen</Heading>
        <Text mode="normal">Als u meer informatie wilt ontvangen, of vragen hebt over de privacy policy van Crossworx en specifiek crossworx.be, kun u ons benaderen via e-mail. Ons e-mailadres is info@crossworx.be.</Text>
        <br /><br />

        <Heading type="h3">Artikel 4 - Monitoren gedrag bezoeker</Heading>
        <Text mode="normal">
          crossworx.be maakt gebruik van verschillende technieken om bij te houden wie de website bezoekt, hoe deze bezoeker zich op de website gedraagt en welke pagina’s worden bezocht. Dat is een gebruikelijke manier van werken voor websites omdat het informatie oplevert op die bijdraagt aan de kwaliteit van de gebruikerservaring. De informatie die we, via cookies, registreren, bestaat uit onder meer IP-adressen, het type browser en de bezochte pagina’s.
        <br /><br />
        Tevens monitoren we waar bezoekers de website voor het eerst bezoeken en vanaf welke pagina ze vertrekken. Deze informatie houden we anoniem bij en is niet gekoppeld aan andere persoonlijke informatie.
        </Text>
        <br /><br />

        <Heading type="h3">Artikel 5 - Gebruik van cookies</Heading>
        <Text mode="normal">
          crossworx.be plaatst cookies bij bezoekers. Dat doen we om informatie te verzamelen over de pagina’s die gebruikers op onze website bezoeken, om bij te houden hoe vaak bezoekers terug komen en om te zien welke pagina’s het goed doen op de website. Ook houden we bij welke informatie de browser deelt.
        </Text>
        <br /><br />

        <Heading type="h3">Artikel 6 - Cookies uitschakelen</Heading>
        <Text mode="normal">
          U kunt er voor kiezen om cookies uit te schakelen. Dat doet u door gebruik te maken de mogelijkheden van uw browser. U vindt meer informatie over deze mogelijkheden op de website van de aanbieder van uw browser.
        </Text>
        <br /><br />

        <Heading type="h3">Artikel 7 - Cookies van derde partijen</Heading>
        <Text mode="normal">
          Het is mogelijk dat derde partijen, zoals Google, op onze website adverteren of dat wij gebruik maken van een andere dienst. Daarvoor plaatsen deze derde partijen in sommige gevallen cookies. Deze cookies zijn niet door crossworx.be te beïnvloeden.
        </Text>
        <br /><br />

        <Heading type="h3">Artikel 9 - Privacy policy van adverteerders/derde partijen</Heading>
        <Text mode="normal">
          Voor meer informatie over de privacy policy van onze adverteerders en derde partijen die verbonden zijn aan deze website, kun u terecht op de websites van deze respectievelijke partijen. crossworx.be kan geen invloed uitoefenen op deze cookies en de privacy policy van door derden geplaatste cookies. Deze cookies vallen buiten het bereik van de privacy policy van crossworx.be.
        <br /><br />
        Als u vragen heeft over dit privacy beleid, kunt u contact met ons opnemen:
        <br /><br />
        Crossworx<br />
        Gerard de Cremerstraat 29<br />
        2650 Edegem<br />
        BE 0700.610.313<br /><br />

        T +3287584091<br />
        I www.crossworx.be<br />
        E info@crossworx.be<br />
        </Text>
      </Content>
    </Root>
  )
}

const Content = styled.div`
  margin-bottom: 10rem;
`;